import { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { Button } from '../../core/button';
import HcLink from '../../core/layout/link';
import HcText from '../../core/layout/text';
import StaticPageLogo from '../../logo';
import Link from 'next/link';
import Router from 'next/router';

import { Account } from "../../../store/account";

import api from "../../../api";

import { UserModel } from "../../../gen/openapi";

function Header() {
  const [userInfo, setUserInfo] = useState<UserModel>(null);

  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);

  const [isMenuOpen, setMenuOpen] = useState<any>(false);

  useEffect(() => {
      let isMounted = true;

      const getUser = async () => {
          let userInfo: UserModel;
          let isTokenValid: boolean = false;

          let _user: UserModel = JSON.parse(Account.User.get());

          if (_user) {
              const response = await api.request({
                  api: 'AccountApi',
                  method: 'apiAccountUsersCognitoUserIdUserGet',
                  params: [
                      Account.CognitoUserName.get()
                  ],
                  spinner: false
              });

              isTokenValid = response && response.data && response.success;

              userInfo = _user;
          }
          else
              userInfo = {};

          if (isMounted) {
              setIsTokenValid(isTokenValid);

              setUserInfo(userInfo);
          }
      }

      getUser();

      return () => { isMounted = false };
  }, []);

  const onMenuClick = () => setMenuOpen((prev) => !prev)

  return (
    <header className="landing-page-header">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <StaticPageLogo />
          <div
            className={`landing-menu-container ${
              isMenuOpen ? 'active' : ''
            }`}
            style={{ marginLeft:'100px' }}
          >
            <ul className={'landing-menu'}>
              <li>
                <Link href={`#features`}>Products & Features</Link>
              </li>
              <li>
                <Link href={`/`} legacyBehavior>
                  <a
                    onClick={async () => {
                      localStorage.setItem('lastSelectedPlan', 'Basic');

                      await Router.push(
                          {
                            pathname: '/pricing',
                            query: {
                              byPass: true,
                              lastSelectedPlan: 'Basic',
                            },
                          },
                          '/pricing'
                      );
                    }}
                  >
                    Plans & Pricing
                  </a>
                </Link>
              </li>
              <li>
                <Link href={`/`} legacyBehavior>
                  <a
                    onClick={async () => {
                      await Router.push(
                          {
                            pathname: '/contact-us',
                          },
                          '/contact-us'
                      );
                    }}
                  >
                    Contact Us
                  </a>
                </Link>
              </li>
            </ul>
          </div>
          {userInfo && (
              <div className="d-flex align-items-center landing-menu-right">
                {(Object.keys(userInfo).length == 0 || (Object.keys(userInfo).length && !isTokenValid)) && (
                    <HcLink size="medium" as={Link} href={`/sign-in`}>
                      <HcText
                          size="medium"
                          as={'u'}
                          className="sign-in-link"
                      >
                        Sign In
                      </HcText>
                    </HcLink>
                )}
                <Button
                  onClick={async () => {
                    let page = (Object.keys(userInfo).length && isTokenValid) ? '/workspace' : '/sign-up';

                    await Router.push(
                        {
                              pathname: page,
                            },
                        page
                    );
                  }}
                  className={'get-started-btn'}
                >
                  {Object.keys(userInfo).length && isTokenValid ? 'Workspace' : 'Try For Free'}
                </Button>
                <button
                    onClick={onMenuClick}
                    className={'menu-trigger'}
                    type="button"
                >
                  <svg
                      fill="#000000"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      width="30px"
                      height="30px"
                  >
                    <path
                        d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z"
                    />
                  </svg>
                </button>
              </div>
          )}
        </div>
      </Container>
    </header>
  );
}

export { Header };
