import * as React from 'react';
import Image from "next/legacy/image";
import Link from 'next/link';
import Router from "next/router";

import medicalCodesIllustration from '/public/assets/images/logo-medical-codes.png';

const StaticPageLogo: React.FunctionComponent = (props) => {
    let logoLink = '/';

    return (
        <Link href="/" legacyBehavior>
            <a
                className="static-page-logo"
                onClick={async () => {
                    await Router.push(
                        {
                            pathname: logoLink,
                        },
                        logoLink
                    );
                }}
            >
            <Image
                src={medicalCodesIllustration}
                alt="medical-codes"
                width={100}
                height={50}
            />
        </a>
    </Link>
  );
};

export default StaticPageLogo;
